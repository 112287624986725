<template>
  <div>
    <b-row class="py-2 py-lg-3 row-gap-lg" no-gutters>
      <b-col sm="12" md="6" lg="4">
        <div class="card rounded-large shadow-sm border-none">
          <div class="card-body">
            <div
              :class="
                form.is_consent === 1
                  ? 'icon-profile-consent mx-auto'
                  : 'icon-profile mx-auto'
              "
              :style="'background-image: url(' + form.picture + ');'"
            >
              <span v-if="!form.picture">
                {{
                  form.first_name_th
                    ? form.first_name_th.substring(1, 0).toUpperCase()
                    : ""
                }}
              </span>
            </div>

            <div class="position-check">
              <!-- <font-awesome-icon icon="check-circle" class="check-circle" /> -->
              <font-awesome-icon
                class="check-circle pointer"
                icon="pencil-alt"
                @click.prevent="sidebarEditProfile"
              />
            </div>
            <font-awesome-icon
              class="sync-icon"
              icon="sync-alt"
              @click.prevent="isTransfersModal = true"
            />
            <font-awesome-icon
              class="trash-icon"
              icon="trash-alt"
              @click.prevent="openModalDeleteUser"
            />
            <div class="customer-name">
              {{ form.first_name_th }} {{ form.last_name_th }} ({{
                form.member_id
              }})
            </div>
            <div class="text-center">
              <b-badge pill variant="personal" v-if="form.is_company == 0"
                >Personal</b-badge
              >
              <b-badge pill variant="company" v-else
                >Company
                <span v-if="form.company_information == 'ไม่จดทะเบียน'">
                  : Not Register</span
                >
              </b-badge>
              <b-badge
                pill
                :variant="
                  form.is_consent == 1
                    ? 'personal'
                    : form.is_consent == 2
                    ? 'wait-consent'
                    : 'company'
                "
                class="ml-2"
                >Consent :
                {{
                  form.is_consent == 1
                    ? "Yes"
                    : form.is_consent == 2
                    ? "Wait To Consent"
                    : "No"
                }}</b-badge
              >
            </div>
            <div
              class="box"
              v-if="
                $store.state.theme.themeLogo.result.display_profile_complete ==
                  '1' && profile.profile != '100'
              "
            >
              <div class="box-score">{{ Math.round(profile.profile) }}%</div>
              ความครบถ้วนของข้อมูล: {{ Math.round(profile.profile) }}%
            </div>
            <hr />
            <b-row class="customer-detail">
              <b-col sm="6">
                <div>
                  {{
                    form.is_company == 1 &&
                    form.company_information == "จดทะเบียน"
                      ? "Business Reg. No."
                      : "Identification No."
                  }}

                  <font-awesome-icon
                    v-if="form.id_card_image"
                    @click="showCardOverLay()"
                    class="color-primary cursor-pointer"
                    icon="id-card"
                  ></font-awesome-icon>
                </div>
                <div>
                  {{ form.id_card || "-" }}
                </div>
              </b-col>
              <b-col sm="6" v-if="form.is_company == 1"></b-col>
              <template v-if="form.is_company == 1">
                <b-col sm="6">
                  <div>Business Type</div>
                  <div>
                    {{ form.company_type || "-" }}
                  </div>
                </b-col>
              </template>
              <b-col sm="6">
                <template v-if="form.is_company == 1">
                  <div>Branch</div>
                  <div v-if="form.company_branch_type == 'สำนึกงานใหญ่'">
                    {{ form.company_branch_type }}
                  </div>
                  <div v-else-if="form.company_branch_type">
                    {{ form.company_branch_no || "-" }}
                    <br />
                    {{ form.company_branch || "-" }}
                  </div>
                </template>
              </b-col>

              <b-col sm="6" v-if="form.is_company == 0">
                <div>Birthday</div>
                <div>
                  {{
                    $moment(form.birthday).year() == "0001" ||
                    $moment(form.birthday).year() == "1752" ||
                    !form.birthday
                      ? "-"
                      : `${$moment(form.birthday).format($formatDateNew)}`
                  }}
                  <span class="text-gray">
                    {{
                      $moment(form.birthday).year() == "1752"
                        ? ""
                        : countDuration("birthday")
                    }}</span
                  >
                </div>
              </b-col>
              <b-col sm="6" v-if="form.is_company == 0">
                <div>Gender</div>
                <div>{{ form.gender || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Register Date</div>
                <div>
                  {{ form.created_time | moment($formatDateNew) }}
                  <span class="text-gray">
                    {{ countDuration("created_time") }}</span
                  >
                </div>
              </b-col>
              <b-col sm="6">
                <div>
                  Telephone
                  <template>
                    ({{
                      form.optional_telephone
                        ? form.optional_telephone.split(",").length + 1
                        : 1
                    }})</template
                  >
                  <u
                    class="color-primary cursor-pointer f-10 ml-2"
                    @click="$refs.optionalModal.show()"
                    >ทั้งหมด</u
                  >
                </div>
                <div>
                  <b-img
                    :src="`https://flagcdn.com/48x36/${
                      form.telephone_country_code
                        ? form.telephone_country_code.toLowerCase()
                        : 'th'
                    }.png`"
                    style="width: 18px; height: 14px; margin-right: 5px"
                  ></b-img>
                  ({{ form.telephone_country_dial_code }})
                  {{ convertTelePhone(form.telephone) }}
                  (หลัก)
                </div>
              </b-col>
              <b-col sm="6">
                <div>Email</div>
                <div>{{ form.email || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Connected Channel</div>
                <div class="mt-2 d-flex" style="gap: 5px">
                  <img
                    class="market-image"
                    src="http://api-hourglass-admin-dev.dosetech.co/Upload/220323100329372.png"
                    alt="dcrm shop"
                  />
                  <div
                    v-for="connect of form.connect_channel"
                    :key="connect.id"
                  >
                    <img
                      class="market-image"
                      :src="connect.marketplaceUrl"
                      alt="marketplace image"
                    />
                  </div>
                </div>
              </b-col>

              <b-col sm="12">
                <!-- <template v-if="!editCustomerType"> -->
                <div class="content-between">
                  <div style="margin-bottom: 5px">Customer Type</div>
                </div>
                <div>
                  {{ form.customer_type || "-" }}
                </div>
              </b-col>
              <b-col sm="12">
                <div class="content-between">
                  <div>Social Media</div>
                </div>

                <template v-if="form.social && form.social.length > 0">
                  <template v-for="$social of form.social">
                    <div
                      :key="$social.id"
                      class="d-flex align-items-center mb-2"
                    >
                      <b-img
                        :src="$social.banner"
                        style="width: 25px; height: 25px"
                        class="mr-2"
                      ></b-img>
                      {{ $social.value || "-" }}
                      <b-button
                        variant="custom-link"
                        v-clipboard:copy="$social.value"
                        v-clipboard:success="onCopySuccess"
                      >
                        <font-awesome-icon
                          icon="copy"
                          class="ml-2 color-primary"
                          v-if="$social.value"
                        />
                      </b-button>
                    </div>
                  </template>
                </template>
                <template v-else> - </template>
              </b-col>
              <b-col sm="12">
                <div class="content-between">
                  <div>Full Address</div>
                  <font-awesome-icon
                    @click="sidebarEditAddress()"
                    icon="pencil-alt"
                    title="Edit"
                    class="main-color cursor-pointer"
                  />
                </div>
                <!-- <div> -->
                <div v-html="displayAddress(form)"></div>
                <!-- {{ fullAddr(form).trim() == "" ? "-" : fullAddr(form) }} -->
                <!-- </div> -->
              </b-col>
              <b-col cols="12">
                <hr />

                <template>
                  <div class="content-between mb-2">
                    <b>Additional Questions</b>
                    <font-awesome-icon
                      @click="$refs.sidebarEditQues.show()"
                      icon="pencil-alt"
                      title="Edit"
                      class="main-color cursor-pointer"
                    />
                  </div>
                  <div
                    v-for="custom_field of form.user_custom_field"
                    :key="custom_field.id"
                  >
                    <template v-if="custom_field.field_type_id != 13">
                      <div
                        :class="[
                          custom_field.field_type_id == 10 ||
                          custom_field.field_type_id == 11
                            ? 'text-bold text-black '
                            : 'f-14',
                          { 'f-16': custom_field.field_type_id == 11 },
                          { 'f-18': custom_field.field_type_id == 10 },
                        ]"
                      >
                        {{ custom_field.field_language[0].name }}
                      </div>
                      <template v-if="custom_field.user_answer.length > 0">
                        <div
                          v-for="answer of custom_field.user_answer"
                          :key="answer.id"
                        >
                          <span v-if="custom_field.field_type_id == 8">
                            <b-img
                              :src="answer.answer"
                              class="w-100 h-100"
                            ></b-img>
                          </span>
                          <span v-else-if="custom_field.field_type_id == 5">
                            {{
                              $moment(answer.answer).format(
                                "DD MMM YYYY (HH:mm)"
                              )
                            }}
                          </span>

                          <span v-else-if="custom_field.field_type_id == 7">
                            {{ $moment(answer.answer).format("DD MMM YYYY") }}
                          </span>
                          <span v-else>{{ answer.answer || "-" }} </span>
                        </div>
                      </template>
                      <template v-else> - </template>
                    </template>
                    <div v-else class="mb-2"></div>
                  </div>
                </template>
              </b-col>

              <b-col cols="12">
                <hr />
                <TabNoteDetail
                  :noteList="noteList"
                  @updateNote="(note) => $emit('updateNote', note)"
                />
              </b-col>
              <b-col cols="12">
                <hr />
                <div class="content-between mb-2">
                  <b>Additional Tag</b>
                  <font-awesome-icon
                    @click="$refs.sidebarEditTag.show()"
                    icon="pencil-alt"
                    title="Edit"
                    class="main-color cursor-pointer"
                  />
                </div>
                <div class="card">
                  <div class="p-1" v-if="form.user_tag.length > 0">
                    <b-row no-gutters
                      ><b-col
                        v-for="(tags, i) of form.user_tag"
                        :key="tags.id + '-' + i"
                        cols="3"
                        class="p-1"
                      >
                        <b-badge class="w-100 f-12" v-if="i < 11">{{
                          tags.name
                        }}</b-badge>
                        <div
                          v-else-if="i == 11"
                          class="text-center cursor-pointer"
                          @click="$refs.sidebarEditTag.show()"
                        >
                          + {{ form.user_tag.length + 1 - 12 }} Tags
                        </div>
                      </b-col></b-row
                    >
                  </div>
                  <div class="p-1 text-center" v-else>
                    <b-button @click="$refs.sidebarEditTag.show()"
                      >Add new Tag</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <pre>{{ form }}</pre> -->
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="8" class="pl-md-3">
        <div class="card rounded-large shadow-sm border-none">
          <b-tabs
            content-class="p-3"
            v-model="customerTabIndex"
            nav-class="rounded-large"
          >
            <b-tab title="Member Information">
              <MemberInfo
                :form="form"
                :branchList="branchList"
                @getData="$emit('getData')"
                @fetch="(val) => $emit('fetch', val)"
              />
            </b-tab>
            <b-tab
              title="Billing Address"
              v-if="form.user_setting.address_per_unit != ''"
            >
              <BillingInfo
                v-if="customerTabIndex == 1"
                :formMain="form"
                @getData="$emit('getData')"
                @fetch="$emit('fetch')"
                :limit="form.user_setting.address_per_unit"
                :getField="getFields"
                :addressOptions="choice"
              />
            </b-tab>
            <b-tab
              title="Shipping Address"
              v-if="form.user_setting.address_per_unit != ''"
            >
              <ShippingInfo
                v-if="customerTabIndex == 2"
                :formMain="form"
                @getData="$emit('getData')"
                @fetch="$emit('fetch')"
                :getField="getFields"
                :limit="form.user_setting.address_per_unit"
                :addressOptions="choice"
              />
            </b-tab>
            <b-tab
              title="Contact Address"
              v-if="form.user_setting.address_per_unit != ''"
            >
              <ContactInfo
                :formMain="form"
                v-if="customerTabIndex == 3"
                @getData="$emit('getData')"
                @fetch="$emit('fetch')"
                :getField="getFields"
                :limit="form.user_setting.address_per_unit"
                :addressOptions="choice"
              />
            </b-tab>

            <b-tab
              v-for="custom of customObject"
              :key="'custom-object-' + custom.id"
              :title="custom.name"
              @click="handleChangeCustomObject(custom.id)"
            >
              <CustomObject
                :ref="'refsCustomObject-' + custom.id"
                :data="custom"
                :id="custom.id"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <SidebarEditProfile
      v-if="form.is_company == 0"
      ref="sidebarProfileEdit"
      :formMain="form"
      :memberLevelList="memberLevelList"
      :FieldList="FieldList"
      :socialList="socialList"
      :v="v"
      @getData="$emit('getData')"
      @openModalPoint="openModalPoint"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
    />
    <SidebarEditCompany
      v-if="form.is_company == 1"
      ref="sidebarCompanyEdit"
      :formMain="form"
      :memberLevelList="memberLevelList"
      :FieldListMain="FieldList"
      :socialList="socialList"
      :v="v"
      @getData="$emit('getData')"
      @setForm="(val) => $emit('setForm', val)"
      @openModalPoint="openModalPoint"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
    />
    <SidebarEditTag
      ref="sidebarEditTag"
      :form="form.user_tag"
      @fetch="$emit('fetch')"
    />
    <SidebarEditQuestion
      ref="sidebarEditQues"
      :form="form.user_custom_field"
      :branchCustomField="branchCustomField"
      @fetch="$emit('fetch')"
    />
    <SidebarEditAddress
      ref="sidebarAddressEdit"
      :formMain="form"
      :addressOptions="choice"
      @updateAddress="updateAddress"
      v-if="showEditAddress"
    />
    <ModalDeleteUser ref="modalDeleteUser" @deleteUser="deleteUser" />
    <CardOverLay
      :img="form.id_card_image"
      @close="overlay = false"
      v-if="overlay"
      :type="file.type"
    />

    <OptionalModal :optionalList="form" ref="optionalModal" />
    <b-modal
      v-model="isTransfersModal"
      size="lg"
      hide-header
      centered
      @hidden="onHide"
    >
      <div class="header-tablepage mb-3">Transfer Data</div>
      <b-form-checkbox
        id="checkbox-1"
        v-model="transfers.IsTransferPoint"
        name="checkbox-1"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all points
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-2"
        v-model="transfers.IsTransferTransaction"
        name="checkbox-2"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all transactions and services
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-3"
        v-model="transfers.IsTransferCoupon"
        name="checkbox-3"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all coupons
      </b-form-checkbox>
      <b-input-group class="mt-3">
        <b-form-input
          type="text"
          id="search"
          class="search-bar"
          name="tranfers-search"
          @keyup.enter="handleSearch"
          placeholder="Search Telephone, Member ID."
          v-model="search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
      </b-input-group>
      <div
        :class="[
          transfers.ToUserGUID == transfersToUser.user_guid
            ? 'highlight-border'
            : '',
          'chart-rounded container shadow-sm mt-3 py-3 border-trans',
        ]"
        v-if="isSearch"
      >
        <template v-if="isLoading">
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-else>
          <template v-if="!notFound">
            <b-row class="align-items-center text-black">
              <b-col cols="2" class="text-bold">Member ID</b-col>
              <b-col cols="2" class="text-bold">Telephone</b-col>
              <b-col cols="2" class="text-bold">First name</b-col>
              <b-col cols="2" class="text-bold">Last name</b-col>
              <b-col cols="2"></b-col>
              <div class="break-normal"></div>
              <b-col cols="2">{{ transfersToUser.member_id }}</b-col>
              <b-col cols="2">{{
                convertTelePhone(transfersToUser.telephone)
              }}</b-col>
              <b-col cols="2">{{ transfersToUser.first_name_th }}</b-col>
              <b-col cols="2">{{ transfersToUser.last_name_th }}</b-col>
              <b-col cols="2" class="ml-auto"
                ><b-button
                  variant="primary"
                  block
                  @click="transfers.ToUserGUID = transfersToUser.user_guid"
                >
                  <font-awesome-icon
                    v-if="transfers.ToUserGUID == transfersToUser.user_guid"
                    class=""
                    icon="check"
                    @click.prevent="sidebarEditProfile"
                  />
                  Select
                </b-button></b-col
              >
            </b-row>
          </template>
          <template v-else>
            <div class="text-info text-center">Member not found</div></template
          >
        </template>
      </div>
      <div v-if="showMessage != ''" class="text-danger mt-3">
        {{ showMessage }}
      </div>
      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button
              variant="outline-primary"
              @click.prevent="isTransfersModal = false"
              class="border-btn"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" @click.prevent="tranfersData">
              Confirm
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SidebarEditProfile from "@/components/report/customer/detail/profile/SidebarEditProfile";
import SidebarEditCompany from "@/components/report/customer/detail/profile/SidebarEditCompany";
import SidebarEditTag from "@/components/report/customer/detail/profile/SidebarEditTag";
import SidebarEditQuestion from "@/components/report/customer/detail/profile/SidebarEditQuestion";
import SidebarEditAddress from "@/components/report/customer/detail/profile/SidebarEditAddress";
import ModalDeleteUser from "./modal/DeleteUser";
import TabNoteDetail from "@/components/report/customer/detail/TabNoteDetail";
import CustomObject from "./components/CustomObject";
import ShippingInfo from "./components/other/ShippingInfo";
import ContactInfo from "./components/other/ContactInfo";
import CardOverLay from "./components/CardOverLay";

import OptionalModal from "./components/Optional";
import BillingInfo from "./components/other/BillingInfo";
import MemberInfo from "./components/MemberInfo";

export default {
  components: {
    SidebarEditProfile,
    SidebarEditCompany,
    SidebarEditAddress,
    SidebarEditTag,
    ModalDeleteUser,
    MemberInfo,
    ShippingInfo,
    ContactInfo,
    BillingInfo,
    CustomObject,
    SidebarEditQuestion,
    TabNoteDetail,
    CardOverLay,
    OptionalModal,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },

    v: {
      required: true,
      type: Object,
    },
    customerQuestionList: {
      required: true,
      type: Array,
    },
    isLoadingPersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
    branchCustomField: {
      required: false,
    },
    noteList: {
      required: false,
    },
    branchList: {
      required: false,
    },
  },
  data() {
    return {
      overlay: false,
      customObject: [],
      profile: { profile: 100 },
      isTransfersModal: false,
      options: [
        { text: "Transfer all points", value: "0" },
        { text: "Transfer all transactions and services", value: "1" },
        { text: "Transfer all coupons", value: "2" },
      ],
      search: "",
      transfers: {
        IsTransferPoint: 1,
        IsTransferTransaction: 1,
        IsTransferCoupon: 1,
        FromUserGUID: "",
        ToUserGUID: "",
      },
      transfersToUser: {
        user_guid: "",
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        telephone: "",
      },
      notFound: false,
      isLoading: false,
      isSearch: false,
      showMessage: "",
      memberLevelList: [],
      FieldList: [],
      socialList: [],
      choice: {
        province: [],
        district: [],
        subdistrict: [],
        zip_code: [],
        gender: [],
        prefix: [],
      },
      customerTabIndex: 0,
      showEditAddress: false,
      editCustomerType: false,
      file: {
        type: "",
        size: "",
      },
    };
  },
  created() {
    this.checkSizeAndType();
    this.getCustomObject();
    this.getProfileComplete();
  },
  watch: {
    customerTabIndex(val) {
      // if (this.choice.province.length == 0) this.getFields();
    },
  },
  methods: {
    async inputCustomerType(e) {
      if (e.charCode == 13) {
        this.$bus.$emit("showLoading");
        const result = await this.axios.post("/customer/customer_type", {
          user_guid: this.$route.params.id,
          customer_type: this.form.customer_type,
        });
        this.$bus.$emit("hideLoading");
        if (result.data.result) this.editCustomerType = false;
      }
    },
    async getProfileComplete() {
      const resp = await this.axios(
        `/customer/GetCompleteProfile/${this.$route.params.id}`
      );
      this.profile = resp.data.detail;
    },
    fullAddr(form) {
      if (form.country == "ประเทศไทย") {
        let addr = `${form.home_address || ""} ${form.alley || ""} ${
          form.road || ""
        } ${form.town || ""} ${form.subdistrict || ""} ${form.district || ""} ${
          form.province || ""
        } ${form.zip_code || ""}`;
        return addr.trim() == "" ? "-" : `${addr} ${form.country || ""}`;
      } else {
        let addr = `${form.home_address || ""}
        ${form.subdistrict || ""} ${form.district || ""}
        ${form.province || ""} ${form.zip_code || ""}`;
        return addr.trim() == "" ? "-" : `${addr} ${form.country || ""}`;
      }
    },
    async getCustomObject() {
      const resp = await this.axios.post(
        `/CustomObject/custom_object_head/customer_page`,
        {
          user_guid: this.$route.params.id,
        }
      );
      this.customObject = resp.data.detail;
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    async getFields(type = 1) {
      return new Promise(async (resolve, reject) => {
        try {
          if (type == 2) {
            const { data } = await this.axios(`/setting/getcompanyform`);
            let province = data.detail.profileField.findIndex(
              (el) => el.field_profile_type_id == 18
            );

            data.detail.profileField[province].field_choices =
              await this.$store.dispatch("getProvince");
            this.FieldList = data.detail.profileField;
          } else {
            if (this.FieldList.length == 0)
              await this.$store.dispatch("getFieldProfiletypes");

            let data = this.$store.state.form.profileLists;

            let province = await data.detail.fields.findIndex(
              (el) => el.field_profile_type_id == 18
            );

            data.detail.fields[province].field_choices =
              await this.$store.dispatch("getProvince");

            this.choice = {
              province: data.detail.fields[province],
              district: await data.detail.fields.find(
                (el) => el.field_profile_type_id == 17
              ),
              subdistrict: await data.detail.fields.find(
                (el) => el.field_profile_type_id == 16
              ),
              prefix: await data.detail.fields.find(
                (el) => el.field_profile_type_id == 2
              ),
              gender: await data.detail.fields.find(
                (el) => el.field_profile_type_id == 21
              ),
            };
            this.FieldList = data.detail.fields;
          }
          resolve(this.choice);
        } catch (error) {
          reject(error);
        }
      });
    },
    async checkSizeAndType() {
      if (this.form.id_card_image) {
        const image = await this.$Axios(this.form.id_card_image, {
          responseType: "blob",
        });

        const imageBlob = await image.data;
        let type = imageBlob.type.split("/")[1];

        return (this.file = { type, size: imageBlob.size });
      }
    },
    async showCardOverLay() {
      await this.checkSizeAndType();
      this.overlay = true;
    },
    async getSocialMedia() {
      const result = await this.axios(`customer/GetSocialMediaListAll`);

      for (const social of result.data.detail) {
        social.social_media_type_id = social.id;

        const $social = this.form.social.find(
          (el) => social.id == el.social_media_type_id
        );
        if ($social) {
          social.id = $social.id;
          social.value = $social ? $social.value : "";
        } else {
          social.id = 0;
        }
      }
      this.socialList = result.data.detail;
    },
    async sidebarEditProfile() {
      if (this.form.is_company) {
        this.$bus.$emit("showLoading");
        await this.getMemberLevelList();
        await this.getFields(2);
        await this.getSocialMedia();
        this.$bus.$emit("hideLoading");
        this.$refs.sidebarCompanyEdit.show();
      } else {
        this.$bus.$emit("showLoading");
        await this.getMemberLevelList();
        await this.getFields();
        await this.getSocialMedia();
        this.$bus.$emit("hideLoading");
        this.$refs.sidebarProfileEdit.show();
      }
    },
    openModalDeleteUser() {
      this.$refs.modalDeleteUser.show();
    },
    async deleteUser(val) {
      this.$refs.modalDeleteUser.hide();

      let message = "";
      if (val == 0)
        message =
          "Do you confirm to delete account and move data to anonymous user";
      else
        message = "Do you confirm to delete account and all data permanently";
      this.confirmAlert({ message: message }).then(async (isCheck) => {
        if (isCheck.value) {
          this.$bus.$emit("showLoading");
          var response = null;
          if (val == 0) {
            response = await this.axios.delete(
              `/User/DeleteUserChangeAnnonymous/${this.form.user_guid}`
            );
          } else {
            response = await this.axios.delete(
              `/User/DeleteUserAllData/${this.form.user_guid}`
            );
          }
          this.$bus.$emit("hideLoading");
          if (response.data.result) {
            this.successAlert().then(() =>
              this.$router.replace("/report/customer")
            );
          } else {
            this.errorAlert(response.data.message);
          }
        }
      });
    },
    async sidebarEditAddress() {
      this.$bus.$emit("showLoading");
      await this.getMemberLevelList();
      await this.getFields();
      // await this.getSocialMedia();
      this.showEditAddress = true;
      this.$bus.$emit("hideLoading");
      this.$nextTick(() => this.$refs.sidebarAddressEdit.show());
    },
    updateAddress(value) {
      this.$emit("updateAddress", value);
    },

    openModalPoint(val) {
      this.$emit("openModalPoint", val);
    },

    submitEditPersonal(val) {
      this.$emit("updatePersonal", val);
    },

    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;

      // let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    handleChangeCustomObject(id) {
      this.$refs["refsCustomObject-" + id][0].getData();
    },
    async handleSearch() {
      this.showMessage = "";
      this.isSearch = true;
      this.isLoading = true;
      this.notFound = false;
      const resp = await this.axios.post("/customer/search_transfer_customer", {
        search: this.search,
      });
      if (resp.data.result) this.transfersToUser = resp.data.detail;
      else this.notFound = true;
      this.isLoading = false;
    },
    async tranfersData() {
      if (this.transfers.ToUserGUID != "") {
        this.showMessage = "";
        this.confirmAlert({
          message: `Do you want to transfer data from  ${this.form.first_name_th} ${this.form.last_name_th} (${this.form.member_id}) to ${this.transfersToUser.first_name_th} ${this.transfersToUser.last_name_th} (${this.transfersToUser.member_id})`,
          title: "Are you sure ?",
          icon: "warning",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
        }).then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            try {
              this.transfers.FromUserGUID = this.form.user_guid;
              this.$bus.$emit("showLoading");
              const resp = await this.axios.post(
                "/customer/transfer_customer",
                this.transfers
              );
              this.$bus.$emit("hideLoading");
              if (resp.data.result) {
                this.isTransfersModal = false;
                this.successAlert().then(() => this.$emit("getData"));
              } else {
                this.errorAlert(resp.data.message);
              }
            } catch (error) {
              this.$bus.$emit("hideLoading");
              console.error(error);
            }
          }
        });
      } else {
        this.showMessage = "Please select member to transfer data";
      }
    },
    onHide() {
      this.transfers = {
        IsTransferPoint: 1,
        IsTransferTransaction: 1,
        IsTransferCoupon: 1,
        FromUserGUID: "",
        ToUserGUID: "",
      };
      this.transfersToUser = {
        user_guid: "",
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        telephone: "",
      };
      this.notFound = false;
      this.isLoading = false;
      this.isSearch = false;
      this.showMessage = "";
      this.search = "";
    },
    countDuration(key) {
      let m = this.$moment(this.form[key]);
      let year = this.$moment().diff(this.$moment(this.form[key]), "year");
      m.add(year, "year");
      let month = this.$moment().diff(m, "month");
      let day = this.$moment().diff(this.$moment(this.form[key]), "day");
      if (year > 0 && month > 0)
        return `(${year}Y ${this.$moment(month, "M").format("M")}M)`;
      else if (year > 0 && month <= 0) return `(${year}Y)`;
      else if (year <= 0 && month > 0)
        return `(${this.$moment(month, "M").format("M")}M)`;
      else if (month <= 0) return `(${day}D)`;
      else return ``;
    },
    onCopySuccess: function (e) {
      this.successAlert(`${e.text}`, "You just copied");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-large {
  border-radius: 0.75rem;
  // border-color: unset;o
}
::v-deep .card-body {
  h4 {
    font-weight: 600;
    color: rgb(96, 96, 96);
  }

  color: black;
}

.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  bottom: 0px;
}
.name {
  font-weight: 600;
}
.bg-icon-point {
  background-color: #ffbe33;
  border-radius: 50px;
  color: #ffe8b8;
}
.icon-bd {
  color: var(--primary-color);
}
.bd-social {
  background-color: var(--theme-secondary-color);
  border-radius: 50px;
  padding: 5px 9px;
  border: none;
}
.img-icon {
  height: auto;
  width: auto;
  max-width: 30px;
  max-height: 30px;
}
.lh-25 {
  line-height: 23px;
}
.collapse.width {
  transition: right 0.3s linear, max-width 0.3s linear;
  max-height: 100% auto;
  max-width: 100%;
  min-width: 50px;
  right: 0;
  position: relative;
  display: block;
}
.icon-profile {
  text-align: center;
  border-radius: 50px;
  background-size: contain;
  width: 70px;
  height: 70px;
  padding: 10px 0;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
  position: relative;
}
.icon-profile-consent {
  // background-image: url(var(--background));
  background-size: contain;
  padding: 5px 0;
  text-align: center;
  border: 3px solid #2eb85c;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 15px;
  max-height: 15px;
}
.text-underline {
  text-decoration: underline;
}
.title-color {
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: transparent;
  border: none;
}
.check-circle {
  color: #000000;
  font-size: 1.25rem;
  padding: 3px;
  background-color: #acffc8;
  border-radius: 50px;
  border: 2px solid #2eb85c;
}
.position-check {
  top: 15px;
  left: 53%;
  position: absolute;
}
.question {
  color: gray;
}
.answer {
  color: #000;
}
.customer-name {
  text-align: center;
  font-size: 18px;
  margin: 10px 0px;
}
.customer-detail {
  row-gap: 5px;
  div {
    div:first-child {
      white-space: nowrap;
      color: rgb(159, 159, 159);
      // font-size: 12px;
    }
  }
}

body {
  min-height: 100vh;
  padding: 0 10vh;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-green {
  color: #2eb85c;
}
.text-error {
  color: red;
}

.market-image {
  width: 30px;
  height: auto;
}
.trash-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 18px;
}
.sync-icon {
  position: absolute;
  top: 20px;
  right: 60px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 18px;
}
.badge {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.box {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--theme-secondary-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
}
.box-score {
  padding: 0.5rem 0.45rem;
  margin-right: 0.25rem;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
}
.border-trans {
  border: 1px solid transparent;
}
.highlight-border {
  border-color: var(--primary-color);
}
.badge-personal {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
}
.badge-company {
  background-color: rgb(236, 99, 99);
  color: white;
  padding: 0.4em 0.6em;
}
.badge-wait-consent {
  background-color: rgb(245, 147, 0);
  color: white;
  padding: 0.4em 0.6em;
}
::v-deep .filter-ticket > .multiselect__tags {
  border: 1px solid var(--primary-color);
}
</style>
